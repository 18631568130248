import { createContext, type ReactNode, useContext } from 'react'
import { useAtom } from 'jotai'
import { openaiApiKeyAtom } from '~/lib/stores'
import OpenAI from 'openai'
import { supabase } from '~/lib/supabase'
import { useAuth } from '~/lib/contexts/auth'

export const OpenAIContext = createContext<OpenAI | null>(null)

export const useOpenAI = () => useContext(OpenAIContext)

export const OpenAIProvider = ({ children }: { children: ReactNode }) => {
  const [apiKey] = useAtom(openaiApiKeyAtom)
  const { session } = useAuth()

  let openai: OpenAI | null = null

  if (apiKey) {
    openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true })
  } else if (session) {
    openai = new OpenAI({
      apiKey: '',
      dangerouslyAllowBrowser: true,
      baseURL: `${import.meta.env.VITE_API_URL}/openai`,
      fetch: async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
        const { data } = await supabase.auth.getSession()
        const headers = new Headers(init?.headers)
        headers.set('Authorization', `Bearer ${data.session?.access_token}`)
        return fetch(url, {
          ...init,
          headers,
        })
      },
    })
  }

  return <OpenAIContext.Provider value={openai}>{children}</OpenAIContext.Provider>
}
