import { customAlphabet } from 'nanoid'

const prefixes = {
  user: 'usr',
  chat: 'ch',
} as const

export const createId = (prefix: keyof typeof prefixes, size?: number) => {
  const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', size ?? 20)
  const id = nanoid()

  return `${prefixes[prefix]}_${id}` as const
}
